import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import './page/global/css/reset.css';
import './page/global/css/index.css';
import './page/global/css/flexboxgrid.css';

import Login from "./page/login/login";
import io from "socket.io-client";
import Room from "./page/room/room";
import {Subject} from "rxjs";
import Box from "./page/box/box";

export type UserDto = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: number;
    updatedAt: number;
}


let socketUrl = window.location.origin;

export const socket: SocketIOClient.Socket = io(socketUrl);

type HelloMsg = {
    message: string
}

socket.on('hello', (data: HelloMsg) => {
    console.log(data);
});

const showGuiObservable = new Subject<boolean>();

class App extends React.Component<any, AppState> {

    constructor(props: any) {
        super(props);

        this.state = {
            user: null,
            noGui: true,
        }
    }

    componentDidMount() {
        showGuiObservable.subscribe(noGui => {
            this.setState({
                noGui
            })
        })
    }

    render() {
        if (!this.state.user) {
            fetchUserFromSession(this.setUser.bind(this))
        }

        const {user} = this.state;

        return (
            <Router>
                <Switch>
                    {
                        !user
                            ? (
                                <>
                                <Route exact path="/">
                                    <Login socket={socket} setUser={this.setUser.bind(this)} noGui={this.state.noGui} />
                                </Route>
                                <Route exact path="/box/:boxId">
                                    <Login socket={socket} setUser={this.setUser.bind(this)} noGui={this.state.noGui} />
                                </Route>
                                </>
                            )
                            :  (
                                <>
                                    <Route exact path="/" render={(props) => {
                                        return (
                                            <Room
                                                socket={socket}
                                                match={props.match}
                                                setUser={this.setUser.bind(this)}
                                                user={this.state.user}
                                                logout={() => logoutUser(this.setUser.bind(this))}
                                                noGui={this.state.noGui}
                                            />
                                        )
                                    }} />
                                    <Route exact path="/box/:boxId" render={(props) => {
                                        return (
                                            <Box boxId={props.match.params.boxId}
                                                 socket={socket}
                                                 user={this.state.user}
                                                 setUser={this.setUser.bind(this)}
                                                 logout={() => logoutUser(this.setUser.bind(this))}
                                                 noGui={this.state.noGui}
                                            />
                                        )
                                    }} />
                                </>
                            )
                    }
                </Switch>
            </Router>
        );
    }

    private setUser(user: UserDto) {
        this.setState({
            user
        });
    }
}

type AppState = {
    user: UserDto|null;
    noGui: boolean;
}

function fetchUserFromSession(setUser: Function): void {
    const userString = window.sessionStorage.getItem('user');
    if (!userString) {
        return;
    }

    const user = JSON.parse(userString);
    if (!user.id) {
        return;
    }

    setUser(user);
}

function logoutUser(setUser: Function): void {
    window.sessionStorage.removeItem('user');
    setUser(null);
}

// @ts-ignore
window.noGui = function(noGui: boolean) {
    showGuiObservable.next(noGui);
}

// @ts-ignore
window.VRAMEReady = function() {
    try {
        // @ts-ignore
        $4d.Plugin_GoinPlaces_Ready();
    } catch (e) {
        console.error(e);
    }
}
const getRoomListSubject = new Subject<void>();

getRoomListSubject.subscribe(() => {
    socket.emit('list-rooms');
});

type RoomList = {
    roomId: string,
    label: string,
}

socket.on('rooms-listed',(roomList: string[]) => {
    try {
        //@ts-ignore
        $4d.Plugin_GoinPlaces_ListRooms(roomList)
    } catch (e) {
        console.error(e)
    }
})

// @ts-ignore
window.BP_Event_getRoomList = function (): void {
    getRoomListSubject.next();
}

// @ts-ignore
window.BpEvent_deleteSetting = function (settingId: string): void {
    socket.emit('delete-setting', settingId);
}

socket.on('setting-deleted',() => {
    console.log('setting-deleted')
})


export default App;
