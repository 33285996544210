import React, {Component} from 'react';
import {Subject} from "rxjs"

import Header from "../../component/header/header";
import {UserDto} from "../../App";

import './box.css';
import Progress from "../../element/progress/progress";

export type BoxDto = {
    id: string,
    data: any
}

export type BoxId = {
    boxId: string
}

export type SetupData = {
    RoomUUID: string,
    BoxRoomJSON?: string,
}

const fullScreenSubject = new Subject<boolean>();
const saveBoxSubject = new Subject<BoxDto>();
const boxReadySubject = new Subject<boolean>();

export default class Box extends Component<BoxProps, BoxState> {
    private unityLoaderPromise: any;
    private unityGameInstance: any;

    constructor(props: BoxProps) {
        super(props);

        this.state = {
            progress: 0,
            isLoaded: false,
            boxId: '',
            boxData: ''
        };
    }

    public componentDidMount(): void {
        console.log('DEBUG loading box data for', this.props.boxId)
        const obj : BoxId = {
            boxId: this.props.boxId
        }
        this.props.socket.emit('load-box-data', obj)

        boxReadySubject.subscribe(_ => {
            if (this.state.boxId == '') {
                console.log("DEBUG box data not loaded yet... retrying in 1 second")
                setTimeout(() => boxReadySubject.next(true), 1000)
            } else {
                const obj: SetupData = {
                    RoomUUID: this.props.boxId,
                    BoxRoomJSON: this.state.boxData,
                };
                const jsonString = JSON.stringify(obj);

                if (this.unityGameInstance) {
                    console.log("DEBUG calling WebAPI - Setup with:", jsonString)

                    this.unityGameInstance.SendMessage('WebAPI', 'Setup', jsonString);
                }
            }
        });

        saveBoxSubject.subscribe(boxDto => {
            console.log("DEBUG store box data for", this.props.boxId)
            boxDto.id = this.props.boxId;

            this.props.socket.emit('save-box',  boxDto);
        });

        this.props.socket.on('box-data-loaded', (boxDto?: BoxDto) => {
            console.log("DEBUG loaded box data for", this.props.boxId, boxDto)

            this.setState({
                boxId: this.props.boxId,
                boxData: boxDto?.data || '',
            });
        })

        this.props.socket.on('box-saved', (responseBoxDto: BoxDto) => {
            console.log('DEBUG stored box data', responseBoxDto)
        });

        setTimeout(() => {
            // @ts-ignore
            this.unityLoaderPromise = window.createUnityInstance(document.querySelector('canvas'),
                {
                    dataUrl: "/assets/box/build.data",
                    frameworkUrl: "/assets/box/build.framework.js",
                    codeUrl: "/assets/box/build.wasm",
                    streamingAssetsUrl: "StreamingAssets",
                    companyName: "Goin' Places",
                    productName: "Box",
                    productVersion: "1.3.92",
                }, this.showProgress.bind(this))
                .then((gameInstance: any) => {
                    this.unityGameInstance = gameInstance;

                    this.setState({
                        isLoaded: true
                    })

                    fullScreenSubject.subscribe(toFullScreen => {
                        if (!this.unityGameInstance) {
                            return;
                        }

                        this.unityGameInstance.SetFullScreen(toFullScreen ? 1 : 0);
                    })
                }).catch((message: string) => {
                    alert(message);
                });
        }, 1000);
    };

    public componentWillUnmount(): void {
        setTimeout(() => {
            if (this.unityGameInstance) {

                this.unityGameInstance.Quit(function () {
                    console.log("done!");
                });

                this.unityGameInstance = null;
            }
        }, 1000);
    }

    public render() {
        let hide = '';
        let hideProgress = '';

        if (!this.state.isLoaded) {
            hide = 'hidden';
        } else {
            hideProgress = 'hidden';
        }

        return (
            <>
                <Header user={this.props.user} logout={this.props.logout} noGui={this.props.noGui}/>
                <div className="box">
                    <main>
                        {/* the reason to not have a form is to avoid default commit events */}
                        {hideProgress
                            ? null
                            : <Progress progress={this.state.progress} className={hideProgress} />
                        }
                        <div className={`game-instance ${hide}`}>
                            <div id={"gameInstance"}>
                            <canvas id={"game-canvas"} className={"game-canvas"} />
                            </div>
                        </div>
                    </main>
                </div>
            </>
        );
    }

    private showProgress(progress: number): void {
        this.setState({
            progress,
        });
    }
}

type BoxState = {
    isLoaded: boolean,
    progress: number,
    boxId: string|undefined,
    boxData: string
}

type BoxProps = {
    boxId: string,
    socket: SocketIOClient.Socket,
    user: UserDto | null,
    setUser: any,
    logout: () => void,
    noGui: boolean
}

// @ts-ignore
window.BOXReady = function() {
    console.log("DEBUG BOXReady()")
    boxReadySubject.next(true);
}

// @ts-ignore
window.SetBoxRoomJSON = function (jsonString: string): void {
  console.log("DEBUG SetBoxRoomJSON(...)")
  saveBoxSubject.next({
    id: 'unknown',
    data: JSON.parse(jsonString)
  })
}

// @ts-ignore
window.SetFullScreen = function (toFullScreen: boolean): void {
    fullScreenSubject.next(toFullScreen);
}