import React, {Component} from 'react';
import Header from "../../component/header/header";
import {socket, UserDto} from "../../App";
import {Subject} from "rxjs";

import './login.css';

export enum UserEvents {
    login = 'login',
    password = 'password',
    create = 'create',
    loginSuccess = 'login-success',
    loginError = 'login-error'
}

export type UserLogin = {
    email: string,
    password: string
}

const loginObservable = new Subject<UserLogin>();


export default class Login extends Component<LoginProps, LoginState> {

    constructor(props: any) {
        super(props);

        this.state = {
            email: '',
            password: ''
        };
    }

    public componentDidMount() {
        this.props.socket.on(UserEvents.loginSuccess, (user: UserDto) => {
            setUserToSession(user, this.props.setUser);
        });

        this.props.socket.on(UserEvents.loginError, (data: any) => {
            console.log('error: ', data);
        });

        loginObservable.subscribe((userLogin) => {
            this.setState({
                email: userLogin.email,
                password: userLogin.password
            }, () => this.processLogin(userLogin.email, userLogin.password));
        });

        try {

            socket.on('hello', () => {
                try {
                    // @ts-ignore
                    window['checkUser'](this.setState.bind(this));
                } catch (e) {
                    console.log(e)
                }

            });
        } catch (e) {
            console.log(e)
        }
    }

    public render() {
        if (this.props.noGui) {
            return <React.Fragment />;
        }

        return (
            <>
                <Header user={null} logout={undefined}/>
                <div className="container m-t-1em">
                    <main>
                        {/* the reason to not have a form is to avoid default commit events */}
                        <div className={"row middle-xs"}>
                            <div className={"col-xs-4 col-xs-offset-4"}>
                                <div className={"login-form"}>
                                    <img alt={"logo"} src={"logo_small_no_txt.png"} />
                                    <h3>Login</h3>
                                    <div className={"row"}>
                                        <div className={"col-xs-12"}>
                                            <div className="row">
                                                <input type={"text"} value={this.state.email} placeholder={"Email"}
                                                       onChange={(e) => this.setState({email: e.target.value})}/>
                                            </div>
                                            <div className="row">
                                                <input type={"password"} value={this.state.password} placeholder={"Password"}
                                                       onChange={(e) => this.setState({password: e.target.value})} />
                                            </div>
                                            <div className={"row"}>
                                                <button onClick={() => this.processLogin(this.state.email, this.state.password)}>
                                                    Absenden
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </>
        )
    }

    private processLogin(email: string, password: string): void {
        this.props.socket.emit(UserEvents.login , {
            email,
            password
        });
    }
}

type LoginState = {
    email: string;
    password: string;
}

type LoginProps = {
    socket: SocketIOClient.Socket;
    setUser: any;
    noGui: boolean
}

function setUserToSession(user: UserDto, setUser: Function): void {
    if (!user.id) {
        return;
    }

    window.sessionStorage.setItem('user', JSON.stringify(user));
    setUser(user);
}

// @ts-ignore
window.login = function(email: string, password: string) {
    loginObservable.next({email, password});
}

// @ts-ignore
window.checkUser = function (): void { // from BP defined auto-login function
    // @ts-ignore
    if (!$4d) {
        throw Error('$4d is not found in the global scope');
    }

    // @ts-ignore
    if (!$4d.Plugin_GoinPlaces_Get_User_Pwd) {
        throw Error('$4d.Plugin_GoinPlaces_Get_User_Pwd is not found in the global scope');
    }

    // @ts-ignore
    if (!$4d.Plugin_GoinPlaces_Get_User_Pwd) {
        throw Error('$4d.Plugin_GoinPlaces_Get_User_Pwd is not found in the global scope');
    }

    type UserLogin = {
        user: string,
        password: string
    }

    // @ts-ignore
    $4d.Plugin_GoinPlaces_Get_User_Pwd((userObject: UserLogin) => {
        // @ts-ignore
        window.login(userObject.user, userObject.password);
    });
}