import React from "react";

import {UserDto} from "../../App";

import './header.css';
import { ReactComponent as LogoutIcon } from './logout.svg';

export default function Header({user, logout, noGui}: HeaderMenuProps) {
    if (noGui) {
        return <React.Fragment />;
    }

    return (
        <header className={"header"}>
            <img alt={"goin'places logo"} src={'logo_small_no_txt.png'} className={'logo'} /> <span className={"company-name"}>Goin'Places</span>
            <HeaderMenu logout={logout} user={user} />
        </header>
    )
}

export function HeaderMenu({user, logout}: HeaderMenuProps) {
    if (!user || !user.id) {
        return null;
    }

    return (
        <div className={"header-menu"}>
            <span onClick={logout}><LogoutIcon /></span>
        </div>
    );
}

export type HeaderMenuProps = {
    user: UserDto|null,
    logout?: () => void,
    noGui?: boolean
}