import React from "react";

import './progress.css';

export default function Progress({progress, className}: ProgressProps) {
    return (
        <progress value={progress} className={`unityprogress ${className}`}/>
    )
}

export type ProgressProps = {
    progress: number,
    className: string
}