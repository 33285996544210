import React, {Component} from 'react';
import {Subject} from "rxjs";
import {match} from "react-router";

import Header from "../../component/header/header";
import {UserDto} from "../../App";
import './room.css';
import Progress from "../../element/progress/progress";

const loadRoomSubject = new Subject<LoadRoomData>();
const exportSvgSubject = new Subject<WithLandingPad>();
const takeScreenShotSubject = new Subject<WithLandingPad>();
const saveSettingsSubject = new Subject<SaveSetting>();
const saveGeneralSettingsSubject = new Subject<SaveSetting>();
const getSettingsSubject = new Subject<WithLandingPad>();
const setArticlesSubject = new Subject<string>();
const loadSettingsSubject = new Subject<string>();
const fullScreenSubject = new Subject<boolean>();
const getRoomIdSubject = new Subject();

type WithLandingPad = {
    landingPad: string
}

type SaveSetting = {
    id: string,
    setting: string,
}


type LoadRoomData = {
    roomId: string,
    settingId: string
}

type BpItem = {
    Name: string;
    Id: number;
    Count: number;
}

type BpSceneData = {
    SceneUUID: string;
    Items: BpItem[];
    Settings: string;
}

type BpData = {
    BPSceneData: BpSceneData;
    ProjectURI: string;
    WebToken: null;
    BoxRoom: boolean;
}

export default class Room extends Component<RoomProps, RoomState> {

    private unityLoaderPromise: any;
    private unityGameInstance: any;

    constructor(props: RoomProps) {
        super(props);

        this.state = {
            progress: 0,
            isLoaded: false,
            roomId: '',
            settingId: ''
        }
    }

    public componentDidMount(): void {
        setTimeout(() => {
            // @ts-ignore
            this.unityLoaderPromise = window.createUnityInstance(
                document.querySelector('canvas'),
                {
                    dataUrl: "/assets/vrame/build.data",
                    frameworkUrl: "/assets/vrame/build.framework.js",
                    codeUrl: "/assets/vrame/build.wasm",
                    streamingAssetsUrl: "/assets/vrame/StreamingAssets",
                    companyName: "GOIN' PLACES",
                    productName: "VRAME-4-EVENT",
                    productVersion: "U2020_v1.3.812",
                }, this.showProgress.bind(this))
                .then((gameInstance: any) => {
                    this.unityGameInstance = gameInstance;

                    // @ts-ignore
                    window.vrameInstance = gameInstance;

                    loadRoomSubject.subscribe((loadRoomData) => {
                        this.props.socket.emit('load-room-data', loadRoomData);
                    });

                    fullScreenSubject.subscribe(toFullScreen => {
                        if (!this.unityGameInstance) {
                            return;
                        }

                        this.unityGameInstance.SetFullScreen(toFullScreen ? 1 : 0);
                    })

                    loadSettingsSubject.subscribe(settingId => {
                        if (!this.state.roomId) {
                            console.log("cannot load setting without a room")

                            return;
                        }

                        const loadRoom: LoadRoomData = {
                            roomId: this.state.roomId,
                            settingId: settingId
                        }

                        this.props.socket.emit('load-room-data', loadRoom);
                    })

                    setArticlesSubject.subscribe( articlesJson => {
                        if (!this.state.roomId) {
                            console.log("cannot set articles without a room");
                            return;
                        }

                        this.unityGameInstance.SendMessage('WebAPI', 'LoadArticleListFromJson', articlesJson);
                    })
                }).catch((message: string) => {
                    alert(message);
                });

                this.props.socket.on('room-data-loaded', ( {roomData, roomId, settingId}: {roomData: BpData, roomId: string, settingId: string}) => {
                    // This calls the main function in VRAME to load up the requested room via its UUID.
                    // See API descriptions for more info and how the data is structured.
                    const roomDataJson = JSON.stringify(roomData);

                    if (roomData.BoxRoom) {
                        this.unityGameInstance.SendMessage('WebAPI', 'LoadBoxRoomForBP', roomDataJson);
                    } else {
                        this.unityGameInstance.SendMessage('WebAPI', 'LoadRoomForBP', roomDataJson);
                    }

                    this.setState({
                        isLoaded: true,
                        roomId,
                        settingId
                    });
                });
        }, 1000);

        exportSvgSubject.subscribe(withLandingPad => {
            this.exportSVG(withLandingPad.landingPad);
        });

        takeScreenShotSubject.subscribe(withLandingPad => {
            this.takeScreenshot(withLandingPad.landingPad);
        });

        getSettingsSubject.subscribe(withLandingPad => {
            this.getCurrentBpDataJson(withLandingPad.landingPad);
        });

        getRoomIdSubject.subscribe(() => {
            this.props.socket.emit('get-room-id');
        })

        saveSettingsSubject.subscribe(saveSetting => {
            const parsed = JSON.parse(saveSetting.setting);
            const settingDto = {
                settingId: saveSetting.id,
                items: parsed?.BPSceneData?.Items || [],
                setting: parsed?.BPSceneData?.Settings || '',
            }

            this.props.socket.emit('save-items', settingDto);
        })

        saveGeneralSettingsSubject.subscribe(saveSetting => {
            const parsed = JSON.parse(saveSetting.setting);
            const settingDto = {
                settingId: saveSetting.id,
                items: parsed?.BPSceneData?.Items || [],
                setting: parsed?.BPSceneData?.Settings || '',
            }

            this.props.socket.emit('save-general-items', settingDto);
        })

        this.props.socket.on('send-room-id', (roomID: string) => {
            //@ts-ignore
            window['SetRoomIdForEmbeddingApp'](roomID)
        });
        this.props.socket.on('items-saved', () => {
            //@ts-ignore
            window['SaveSettingCallback'](true)
        });
        this.props.socket.on('items-save-failed', () => {
            //@ts-ignore
            window['SaveSettingCallback'](false)
        });

        this.props.socket.on('general-items-saved', () => {
            //@ts-ignore
            window['SaveGeneralSettingCallback'](true)
        });
        this.props.socket.on('general-items-already-exist', () => {
            //@ts-ignore
            window['SaveGeneralSettingCallback'](false)
        });
    };

    public componentWillUnmount(): void {
        setTimeout(() => {
            if (this.unityGameInstance) {

                this.unityGameInstance.Quit(function () {
                    console.log("done!");
                });

                this.unityGameInstance = null;
            }
        }, 1000);
    }

    public render() {
        let hide = '';
        let hideProgress = '';

        if (!this.state.isLoaded) {
            hide = 'hidden';
        } else {
            hideProgress = 'hidden';
        }

        return (
            <>
                <Header user={this.props.user} logout={this.props.logout} noGui={this.props.noGui}/>
                <div className="room">
                    <main>
                        {/* the reason to not have a form is to avoid default commit events */}
                        <Progress progress={this.state.progress} className={hideProgress} />
                        <div className={`game-instance ${hide}`}>
                            <div id={"gameInstance"}>
                                <canvas id={"game-canvas"} className={"game-canvas"} />
                            </div>
                        </div>
                    </main>
                </div>
            </>
        )
    }

    private showProgress(progress: number): void {
        this.setState({
            progress,
        });
    }

    private exportSVG(landingPad: string): void {
        if (!this.unityGameInstance || !this.state.isLoaded) {
            return;
        }

        this.unityGameInstance.SendMessage('WebAPI', 'GetLayoutSVG', landingPad);
    }

    private takeScreenshot(landingPad: string): void {
        if (!this.unityGameInstance || !this.state.isLoaded) {
            return;
        }

        this.unityGameInstance.SendMessage('WebAPI', 'GetScreenshot', landingPad);
    }

    private getCurrentBpDataJson(landingPad: string): void {
        if (!this.unityGameInstance || !this.state.isLoaded) {
            return;
        }

        this.unityGameInstance.SendMessage('WebAPI', 'GetCurrentBPDataJSON', landingPad);
    }
}

type RoomState = {
    isLoaded: boolean,
    progress: number,
    settingId: string,
    roomId: string,
}

type RoomProps = {
    match: match,
    socket: SocketIOClient.Socket,
    user: UserDto | null,
    setUser: any,
    logout: () => void,
    noGui: boolean
}

// @ts-ignore
window.isProduction = true

// @ts-ignore
window.saveOnFocus = function (): void {
    getSettingsSubject.next({
        landingPad: 'saveSetting'
    })
}

// @ts-ignore
window.saveSetting = function (data: string): void {
    console.log(data)
}


// @ts-ignore
window.loadRoom = function (roomId: string, settingId: string): void {
    loadRoomSubject.next({
        roomId: roomId,
        settingId: settingId
    });
}
// @ts-ignore
window.BpEvent_loadRoom = window.loadRoom

// @ts-ignore
window.getScreenShot = function (landingPad: string): void {
    takeScreenShotSubject.next({landingPad});
}
// @ts-ignore
window.BpEvent_getScreenShot = window.getScreenShot

// @ts-ignore
window.getSVG = function (landingPad: string): void {
    exportSvgSubject.next({landingPad});
}
// @ts-ignore
window.BpEvent_getSVG = window.getSVG

// @ts-ignore
window.getSetting = function (): void {
    getSettingsSubject.next({landingPad: 'GetSettingForEmbeddingApp'});
}

// @ts-ignore
window.BpEvent_getSetting = window.getSetting

// @ts-ignore
window.SaveSettingCallback = function (success: Boolean) {
    // @ts-ignore
    $4d.Plugin_GoinPlaces_Call_Result(success, null)
}

// @ts-ignore
window.saveSetting = function (settingId: string): string {
    // @ts-ignore
    window.SetSettingFromVrame = function(settingJsonString: string) {
        saveSettingsSubject.next({id: settingId, setting: settingJsonString});
    }
    // @ts-ignore
    getSettingsSubject.next({
        landingPad: 'SetSettingFromVrame'
    })

    return settingId;
}
// @ts-ignore
window.BpEvent_saveSetting = window.saveSetting

// @ts-ignore
window.SaveGeneralSettingCallback = function (success: Boolean) {
    // @ts-ignore
    $4d.Plugin_GoinPlaces_Call_Result(success, null)
}

// @ts-ignore
window.saveGeneralSetting = function (settingId: string): string {
    // @ts-ignore
    window.SetGeneralSettingFromVrame = function(settingJsonString: string) {
        saveGeneralSettingsSubject.next({id: settingId, setting: settingJsonString})
    }
    getSettingsSubject.next({
        landingPad: 'SetGeneralSettingFromVrame'
    })

    return settingId;
}

// @ts-ignore
window.BpEvent_saveGeneralSetting = window.saveGeneralSetting

// @ts-ignore
window.loadSetting = function (settingId: string): void {
    loadSettingsSubject.next(settingId);
}

// @ts-ignore
window.BpEvent_loadSetting = window.loadSetting

// @ts-ignore
window.setArticles = function (articlesJson: string): void {
    setArticlesSubject.next(articlesJson);
}

// @ts-ignore
window.BpEvent_setArticles = window.setArticles

// @ts-ignore
window.logResponse = function (response: any): void {
    console.log(response);
}

// @ts-ignore
window.GetSettingForEmbeddingApp = function(settingJson: string) {
    const obj = JSON.parse(settingJson);

    // @ts-ignore
    $4d.Plugin_GoinPlaces_SettingsStr(JSON.stringify(obj?.BPSceneData?.Items || []));
}

// @ts-ignore
window.SetSettingFromVrame = function (_: string) {
    console.log('function should be replaced with a closure containing the settingId - the data will not be stored')
}

// @ts-ignore
window.SetGeneralSettingFromVrame = function (_: string) {
    console.log('function should be replaced with a closure containing the settingId - the data will not be stored')
}


// @ts-ignore
window.SetScreenshotFromVrame = function (base64String: string): void {
    let imageString = base64String;

    if (base64String.indexOf(';') !== -1) { // we usually send the filename attached to the base64image string <base64>;<filename>
        imageString = base64String.split(';')[0];
    }

    // @ts-ignore
    $4d.Plugin_GoinPlaces_Screenshot(imageString); // from BP defined
}

// @ts-ignore
window.SetSVGFromVrame = function(base64String: string): void {
    // @ts-ignore
    $4d.Plugin_GoinPlaces_SVG(base64String); // from BP defined
}

// @ts-ignore
window.SetFullScreen = function (toFullScreen: true): void {
    fullScreenSubject.next(toFullScreen);
}


// @ts-ignore
window.getRoomId = function (): void {
    getRoomIdSubject.next();
}

// @ts-ignore
window.BP_Event_getRoomId = window.getRoomId

// @ts-ignore
window.SetRoomIdForEmbeddingApp = function(roomId: string) {
    // @ts-ignore
    $4d.Plugin_GoinPlaces_RoomID(roomId);
}